$(document).ready(function () {
	customFilterMobileDropdown();
	initEvents();
	initMobileLogo();
	initSplitSlider();
	initPagination();
    initVideoPopups();
    initBooking();
    initBackgroundVideos();
	initVideoButtons();
	initHotelsLanding();
	initHotelDetails();
	initLandingSliderToo();
	initCareersSlider();
	initContactForm();
	// customCookieBar();
	threeGridAltSlider();
	initDateSelection();
	initGHAPortal();
});

var initFeaturedCarousel = function () {
	$('.featured-carousel__inner').slick({
		dots: true,
		arrows: true,
		slidesToShow: 1,
		slidesToScroll: 1
	})
}

var initSlider = function () {
	if ( $('.slider').length === 0 ) {
		$('body').addClass('no-slider');
	}
	
	$('.slider__inner').reformSlider({
		autoPlay: (function () {
			if ( $('.slider__slide iframe').length > 0 ) {
				return false
			} else {
				return true
			}
		})(),
		speed: 7000,
		arrows: false,
		pagination: true,
		onLoad: function () {
			$('.has-mixed-media').addClass('show')
		}
	});
	
	if ( $('.slider__slide iframe').length > 0 ) {
		$('.slider').addClass('has-video');
		coverViewport('.slider__slide iframe', true);
		$(window).on('resize', function () {
			coverViewport('.slider__slide iframe', true);
		});
	}
};


function initLandingSliderToo() {
	$('.landing-slider__slider').slick({
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		centerMode: false,
		centerPadding: '0px',
		responsive: [
			{
				breakpoint: 640,
				settings: {
					centerMode: false,
					centerPadding: '0px',
					slidesToShow: 1,
					infinite: true
				}
			}
		]
	});
	
	setTimeout(function() {
		$('.landing-slider__slider').slick('resize');
		$(window).trigger('resize');
	},100);

	if( $(window).outerWidth() >= 1025 ) {
		$('.landing-slider__slider-slide').mouseenter(function() {
			$(this).addClass('on');
		});
		
		$('.landing-slider__slider-slide').mouseleave(function() {
			$(this).removeClass('on');
		});
	} else {
		$('.landing-slider__slider-slide').each(function() {
			$(this).addClass('on');
		})
	}
};


var initAnimateInView = function () {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
}

function animateElementsInView() {
	$('[data-animate-in-view]').each(function () {
		if ( $(this).hasClass('in-view') ) {
			return;	
		}
		
		if ( isElementInViewport($(this)) ) {
			var elementToAnimate = this;
			
			$(elementToAnimate).addClass('in-view'); // log that this element has been seen
			
			
			setTimeout(function () {
				$(elementToAnimate).addClass( $(elementToAnimate).data('animate-in-view') ); // apply the animation class
				$(elementToAnimate).removeClass( $(elementToAnimate).data('animate-in-view-remove-class') ); // remove the pre-animation class
			}, 300);
			
			
			if ( typeof $(elementToAnimate).data('map-load-on-view') != 'undefined' ) { // only load this map once it's in view
				buildMap(elementToAnimate);
			}
		}
	});
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport (el) {
	if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}
	
	var rect = el.getBoundingClientRect();
	
	var t = rect.y;
	var b = window.innerHeight - rect.y - rect.height;
	var h = window.innerHeight;
	
	if (
		( (t >= 0) && (t <= h) ) ||
		( (b >= 0) && (b <= h) )
	) {
		return true;
	} else {
		return false;
	}
}


function initEvents() {
	$('.events__slider').slick({
		slidesToShow: 3,
		infinite: true,
		arrows: true,
		responsive: [
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}


function customFilterMobileDropdown() {
	if ( $('.gallery').length === 0 ) return;
	
	var filterWatcher = setInterval(function () {
		if ( $('.filter-controls-dropdown select').length === 1 ) {
			clearInterval(filterWatcher);
		}
		$('.filter-controls-dropdown select').selectric();
	}, 500);
}

var initAccordions = function() {

    $('.accordion__inner').accordiom({
        showFirstItem: false
    })
}

var initHeader = function () {
	
	function throttle(fn, threshhold, scope) {
	  threshhold || (threshhold = 250);
	  var last,
	      deferTimer;
	  return function () {
	    var context = scope || this;
	
	    var now = +new Date,
	        args = arguments;
	    if (last && now < last + threshhold) {
	      // hold on to it
	      clearTimeout(deferTimer);
	      deferTimer = setTimeout(function () {
	        last = now;
	        fn.apply(context, args);
	      }, threshhold);
	    } else {
	      last = now;
	      fn.apply(context, args);
	    }
	  };
	}

	// Detect if the header is over and image slider, if yes then give it a transparent background
	setHeaderTransparency();
	$(window).on('scroll', setHeaderTransparency);

	function setHeaderTransparency() {
		if( $('body.home').length > 0 ) {
			if (window.pageYOffset < 150) {
				$('.header').addClass('header--transparent');
			} else {
				$('.header').removeClass('header--transparent');
			}
		}
	}
	
	// Show the burger nav when there isn't enough room for the horizontal nav
	if ( $('.header__horizontal-nav').length > 0 ) {
		$(window).on('resize', function () {
			throttle(function (event) {
				console.log('resizing2');
			}, 1500);
		});
	}
}


var initOtherRooms = function () {
	$('.other-rooms__carousel').slick({
		dots: true,
		arrows: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: 0,
		infinite: true,
		responsive: [
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					adaptiveHeight: true
				}
			}
		]
	});
}




function pad(n, width, z) {
	z = z || '0';
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function initMobileLogo() {
    
    setTimeout(function () {
        $('.header').addClass('visible');
    }, 300);

    $(window).on('scroll', function() {
        var docHeight = $('main').outerHeight();
        var windowHeight = $(window).outerHeight();
        var topOffset = $(document).scrollTop();

		if( ( topOffset > 200 ) && ( $(window).outerWidth() < 1024 ) ) {
			$('.header').addClass('header-scrolled');
		} else {
			$('.header').removeClass('header-scrolled');
		}
    })

}

function initSplitSlider() {
	$('.split-slider__inner').slick({
		arrow: true,
		autoplay: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		lazyLoad: 'progressive'
	})
}

function initPagination() {
	$('.pagination:last').addClass('last');
}



function initVideoPopups() {
    var $modal;
	var modal = '<div id="gallery__modal" class="gallery__modal gallery__modal--video full reveal loading" data-reveal><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><img src="/assets/img/close-careers-button.png"></button><div class="gallery__modal-iframe"><iframe frameborder="0" src="{{iframeSrc}}"></iframe></div></div>';
        
    
    // Detect video links and embed them in a popup
    $('a[href*="youtu"], a[href*="vimeo.com"]').not('[href*="youtube.com/channel"]').addClass('is-video').on('click', function () {
        $modal = $( modal.replace('{{iframeSrc}}', generateVideoEmbedUrl($(this).attr('href'))) );
        
        $('body').append($modal);
        
        $('#gallery__modal').foundation();
        $('#gallery__modal').foundation('open');
        
        $('#gallery__modal').on('closed.zf.reveal', function () {
            $modal.remove();
        });
        
        return false;
    });
}


function generateVideoEmbedUrl(videoUrl) {
    if (videoUrl.indexOf('youtube.com/embed') > -1) {
        // already a YouTube embed code, return same link
        return videoUrl;
    } else if (videoUrl.indexOf('youtu') > -1) {
        // must be either a full or shortened YouTube url so parse and create embed link
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = videoUrl.match(regExp);
    
        if (match && match[2].length == 11) {
            return 'https://www.youtube.com/embed/' + match[2] + '?rel=0&autoplay=1&modestbranding=1';
        } else {
            return videoUrl;
        }		
    } else if (videoUrl.indexOf('player.vimeo.com') > -1) {
        // already a Vimeo embed code, but add some parameters
        if ( videoUrl.indexOf('?') > -1 ) {
            videoUrl += '&';
        } else {
            videoUrl += '?';
        }
        return videoUrl + 'autoplay=true&byline=false&portrait=false&title=false';
    } else if (videoUrl.indexOf('https://vimeo.com/') > -1) {
        // normal Vimeo link, so generate and embed code
        return 'https://player.vimeo.com/video/' + videoUrl.split('/')[3] + '?autoplay=true&byline=false&portrait=false&title=false';
    }
    
    // if all else fails, return the original link
    return embedUrl;
}


function initBooking() {
    $('a[href="#book-table"]').on('click', function () {
        $('.booking iframe').each(function () {
            if ( typeof $(this).data('src') !== 'undefined' ) {
                $(this).attr('src', $(this).data('src'));
            }
        });
        
        $('#booking').foundation('open');
       
       return false; 
    });
}


function initBackgroundVideos() {
    var menusModule = {
        videos: $('.menus .bg-video iframe'),
        parent: $('.menus')
    }
    var socialModule = {
        videos: $('.social .bg-video iframe'),
        parent: $('.social')
    }

    function setCoverViewport (props) {
        if (props.videos.length > 1) return;
        props.parent.addClass('has-bg-video')
        coverViewport(props.videos, false);
        $(window).on('resize', function () {
            coverViewport(props.videos, false);
        });
    }

    setCoverViewport(menusModule)
    setCoverViewport(socialModule)

}

function initVideoButtons() {
	if( ($('.slider__video').length > 0) && ($('body').hasClass('video-has-sound')) ) {
		$('.slider__video').prepend('<button class="slider__video-sound slider__video-sound--on button button--white">sound on</button><button class="slider__video-sound slider__video-sound--off hide button button--white">sound off</button>');
		
		var video = $('.slider__inner iframe');
		var videoPlay = new Vimeo.Player(video);
		var volumeOn = $('.slider__video-sound--on');
		var volumeOff = $('.slider__video-sound--off');
		volumeOff.addClass('hide');
		videoPlay.setVolume(0);

		$('.slider__video-play').on('click', function() {
			videoPlay.play();
			$(this).hide();
		});

		volumeOn.on('click', function() {
			videoPlay.setMuted(false);
			videoPlay.setVolume(0.66);
			volumeOn.toggleClass('hide');
			volumeOff.toggleClass('hide');
		});
		
		volumeOff.on('click', function() {
			videoPlay.setMuted(true);
			videoPlay.setVolume(0);
			volumeOn.toggleClass('hide');
			volumeOff.toggleClass('hide');
		});
	}
}

function initHotelsLanding() {
	if( $(window).outerWidth() >= 640 ) {
		$('.hotels__item').mouseenter(function() {
			$(this).addClass('on');
		});
		
		$('.hotels__item').mouseleave(function() {
			$(this).removeClass('on');
		});
	} else {
		$('.hotels__item').each(function() {
			$(this).addClass('on');
		})
	}
}

function initHotelDetails() {
	if( $(window).outerWidth() >= 640 ) {
		$('.hotel-details__item').mouseenter(function() {
			$(this).addClass('on');
		});
		
		$('.hotel-details__item').mouseleave(function() {
			$(this).removeClass('on');
		});
	} else {
		$('.hotel-details__item').each(function() {
			$(this).addClass('on');
		})
	}
}

function initCareersSlider() {	
	$('.careers-slider__slider').slick({
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		centerMode: false,
		centerPadding: '3%',
		responsive: [
			{
			  breakpoint: 640,
			  settings: {
				centerMode: false,
				centerPadding: '0px',
				slidesToShow: 1
			  }
			}
		]
	});
};

function initContactForm() {
	$('.contact .form select').selectric();
}


// function customCookieBar() {
// 	if ( $('.cookie-bar').length > 0 ) {
// 		if ( !Cookies.get('accepted_cookies') ) {
// 			$('.cookie-bar').fadeIn();
// 		}


// 		$('.cookie-bar__accept').on('click', function () {
// 			Cookies.set('accepted_cookies', 'true', {expires: 30});
// 			$('.cookie-bar').fadeOut();
// 		});
// 	}
// }

$(document).ready(function () {
	if ($(window).width() <= 1024) {
	  $('#filterAccordion').accordiom({
		showFirstItem: false,
		openAll: false,
	  });
	}

	// Set the initial text to "DOWN"
	$('.filter-arrow').text('⌄');
	  
	// Toggle text between "DOWN" and "UP" on filterAccordion click
	$('#filterAccordion').on('click', function () {
	  var arrowText = $('.filter-arrow').text();
	  $('.filter-arrow').text(arrowText === '⌄' ? '⌃' : '⌄');
	});
	  
});

document.addEventListener("DOMContentLoaded", function() {
    var memberships = document.querySelector(".memberships");

	if (memberships) {
		var tabs = memberships.querySelectorAll(".tabs-title");
		var panels = memberships.querySelectorAll(".tabs-panel");
		var prevBtn = memberships.querySelector(".tabs-prev");
		var nextBtn = memberships.querySelector(".tabs-next");

		var currentIndex = 0;

		// Set initial active tab index based on "is-active" class
		for (var i = 0; i < tabs.length; i++) {
			if (tabs[i].classList.contains("is-active")) {
				currentIndex = i;
				break;
			}
		}

		function showPanel(index) {
			for (var i = 0; i < tabs.length; i++) {
				tabs[i].classList.remove("is-active");
				panels[i].classList.remove("is-active");
			}

			tabs[index].classList.add("is-active");
			panels[index].classList.add("is-active");
			currentIndex = index;
		}

		function showNextPanel() {
			var nextIndex = (currentIndex + 1) % panels.length;
			showPanel(nextIndex);
		}

		function showPrevPanel() {
			var prevIndex = (currentIndex - 1 + panels.length) % panels.length;
			showPanel(prevIndex);
		}

		nextBtn.addEventListener("click", showNextPanel);
		prevBtn.addEventListener("click", showPrevPanel);

		// Add click event listener to each tab
		tabs.forEach(function(tab, index) {
			tab.addEventListener("click", function() {
				showPanel(index);
			});
		});
	}
});


function threeGridAltSlider() {
	var slider = $('.three-grid-alt');
	var settings = {
		mobileFirst: true,
		slidesToShow: 1,
		infinite: true,
		arrows: true,
        centerPadding: '40px',
		responsive: [
			{
				breakpoint: 1023,
				settings: "unslick"
			}
		]
	};

	slider.slick(settings);

	$(window).on("resize", function () {
		if ($(window).width() > 1023) {
			return;
		}
		if (!slider.hasClass("slick-initialized")) {
			return slider.slick(settings);
		}
	});
}
function initGHAPortal() {
	$('.header__login-button').on('click', function (e) {
		e.preventDefault();
		$('.header__login').addClass('open');
	});

	$('.header__login').on('mouseleave', function (e) {
		$('.header__login').removeClass('open');
	});

	$('.gha__form input[type="password"]').each(function () {
    $(this).parent().append('<button type="button" class="toggle-password" aria-hidden="hidden" tabindex="-1"></button>');
  });

  $('.toggle-password').on('click', function () {
    var input = $(this).siblings('input')[0];
    if (typeof input !== 'undefined') {
      if (input.type === "password") {
        input.type = "text";
				$(this).addClass('off');
      } else {
				input.type = "password";
				$(this).removeClass('off');
      }
    }
  });

	$('.gha__form select').selectric();

	$('.gha__form select').each(function () {
    customSelectricPlaceholderState(this);
  });

  $('.gha__form select').on('change', function () {
    customSelectricPlaceholderState(this);
  });

  function customSelectricPlaceholderState(selectElement) {
    if ($(selectElement).val() === '') {
      $(selectElement).parents('.selectric-wrapper').addClass('show-as-placeholder');
    } else {
      $(selectElement).parents('.selectric-wrapper').removeClass('show-as-placeholder');
    }
  }

	$('.gha__form-clear').on('click', function (e) {
		e.preventDefault();
		$(this).parents('form').find('input').val('');
	});

	if ($('#gha-portal-tabs').length > 0 && window.location.hash.indexOf('#') === 0) {
		if (window.innerWidth < 640) {
			$('.gha-portal__main-tab-list').addClass('hidden');
		}
	}

	$('.gha-portal__main-tab-list a').on('click', function () {
		if (window.innerWidth < 640) {
			$('.gha-portal__main-tab-list').addClass('hidden');
		}
	});
	
	$('.tabs-panel__title').on('click', function (e) {
		e.preventDefault();
		$('.gha-portal__main-tab-list').removeClass('hidden');
	});

	$('.gha-sign-up form, #password form').on('submit', function () {
		if ($(this).find('input[type="password"]').length > 1) {
			return validateGHAPasswords($(this));
		}
	});

	$('.gha-sign-up input[type="password"]').on('input', function () {
		return validateGHAPasswords($(this).parents('form'));
	});

	$('.gha-sign-up input').on('input', function () {
		var input = $(this)[0];

		if ($(input).attr('pattern') !== '') {
			if (input.validity.patternMismatch) {
				input.setCustomValidity($(this).data('pattern-message'));
			} else {
				input.setCustomValidity('');
			}

			input.reportValidity();
		}
	});

	$('.gha__form-nav .next').on('click', function (e) {
		e.preventDefault();

		if ($(this).parents('form').find('input[type="password"]').length > 1) {
			if (!validateGHAPasswords($(this).parents('form'))) {
				return false;
			}
		}

		var invalidFieldCount = 0;

		$(this).parents('.gha-sign-up__form-wrapper').find('input, select').each(function () {
			if (!$(this)[0].reportValidity()) {
				invalidFieldCount++;
			}
		});

		if (invalidFieldCount === 0) {
			var $currentStepWrapper = $(this).parents('.gha-sign-up__form-wrapper');
			$currentStepWrapper.hide();
			var currentStepNumber = $currentStepWrapper.data('step');
			currentStepNumber++;
			$('.gha-sign-up__form-wrapper[data-step="' + currentStepNumber + '"]').show();
			$('html, body').animate({
				scrollTop: $('.gha-sign-up__form-wrapper[data-step="' + currentStepNumber + '"]').offset().top - 120
			}, 0);
		}
	});

	$('.gha__form-nav .back').on('click', function (e) {
		e.preventDefault();
		var $currentStepWrapper = $(this).parents('.gha-sign-up__form-wrapper');
		$currentStepWrapper.hide();
		var currentStepNumber = $currentStepWrapper.data('step');
		currentStepNumber--;
		$('.gha-sign-up__form-wrapper[data-step="' + currentStepNumber + '"]').show();
		$('html, body').animate({
			scrollTop: $('.gha-sign-up__form-wrapper[data-step="' + currentStepNumber + '"]').offset().top - 120
		}, 0);
	});
}

function initDateSelection() {
	// $('#booking').foundation('open');

	// Only load 3rd party iframes when the booking widget is toggled
	function loadIframes() {
		$('.booking iframe').each(function () {
			if ( typeof $(this).data('src') !== 'undefined' ) {
				$(this).attr('src', $(this).data('src'));
			}
		});
	}

	$('.header__cta button').on('click', function () {
		loadIframes();
		$(window).trigger('resize'); // rejig the equalizer for the date fields
	});



	// Open the booking widget and switch to the book table tab

	$('#book-table-btn').on('click', function () {
		$('#book-rooms').css("display", 'none')
		$('#book-rooms').css("pointer-evetns", 'none')
		$('.booking__tabs-direct').css("display", 'none');

		$('#book-table').show();
		$('#booking').foundation('open');
		$('#nav-book-table').css('background-color', '#7A0026')
	});

	$('a[href*="#bookTable"]').on('click', function () {
		loadIframes();

		$('#book-rooms').css("display", 'none')
		$('#book-rooms').css("pointer-evetns", 'none')
		$('.booking__tabs-direct').css("display", 'none');

		$('#book-table').show();
		$('#booking').foundation('open');
		$('#nav-book-table').css('background-color', '#7A0026')
		$('#nav-book-room').css('background-color', '#000')
	})

	$('#nav-book-table').on('click', function () {

		$('#book-rooms').css("display", 'none')
		$('#book-rooms').css("pointer-evetns", 'none')
		$('.booking__tabs-direct').css("display", 'none');


		$('#book-table').show();
		$('#booking').foundation('open');
		$('#nav-book-table').css('background-color', '#7A0026')
		$('#nav-book-room').css('background-color', '#000')
	})

	$('#book-room-btn,#book-room-btn-mobile').on('click', function () {
		$('#book-rooms form').trigger('submit');
	})

	$('#nav-book-room').on('click', function () {
		$('#book-rooms').css("display", 'block')
		$('#book-rooms').css("pointer-evetns", 'all')
		$('.booking__tabs-direct').css("display", 'block');

		$('#book-table').hide();
		$('#booking').foundation('open');
		$('#nav-book-room').css('background-color', '#7A0026')
		$('#nav-book-table').css('background-color', '#000')
	})


	// Hide any open date pickers when changing tab
	$('#booking-tabs').on('change.zf.tabs', function () {
		$('.datepicker-container').addClass('datepicker-hide');
	});

	var bookingContainer = $('.booking__tabs-content-room form');

	var nowTemp = new Date();
	// Don't allow bookings until the hotel opens
	if ( (nowTemp < new Date('2022-03-01')) && ($('body').hasClass('theme-100-shoreditch')) )  {
		nowTemp = new Date('2022-03-01');
	}


	/*var tomorrow = new Date();
		tomorrow.setDate(nowTemp.getDate() + 1);*/

	var tomorrow = new Date(nowTemp.getTime() + 86400000); // + 1 day in ms
	tomorrow.toLocaleDateString();

	var $checkInField = bookingContainer.find('.check-in');
	var $checkOutField = bookingContainer.find('.check-out');

	$checkInField.on('hide.datepicker', function () {
		var checkInDate  = $checkInField.datepicker('getDate');
		var checkOutDate = $checkOutField.datepicker('getDate');
		var dayAfterCheckIn = new Date(checkInDate);
			dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

		// Ensure checkout comes after checkin.
		// Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
		if ( checkInDate >= checkOutDate ) {
			$checkOutField.datepicker('setDate', dayAfterCheckIn);
		}

		$checkOutField.datepicker('setStartDate', dayAfterCheckIn);

		$checkOutField[0].focus();
	});

	if ( $('body').hasClass('theme-100-shoreditch') ) {

		var checkin = $checkInField.datepicker({
			format: 'dd-mm-yy',
			autoPick: true,
			autoHide: true,
			date: null,
			startDate: nowTemp,

			pick: function (e) {
				// Formatted date for the booking engine
				$('input[name="arrive"]').val( e.date.getFullYear() + '-' + pad( e.date.getMonth() + 1*1 , 2) + '-' + e.date.getDate() )			}
		});

		var checkout = $checkOutField.datepicker({
			format: 'dd-mm-yy',
			autoPick: true,
			autoHide: true,
			date: null,
			startDate: tomorrow,
			pick: function (e) {
				// Formatted date for the booking engine
				$('input[name="depart"]').val( e.date.getFullYear() + '-' + pad( e.date.getMonth() + 1*1 , 2) + '-' + e.date.getDate() );			}
		});

	} else {
		var checkin = $checkInField.datepicker({
			format: 'mm-dd-yy',
			autoPick: true,
			autoHide: true,
			date: null,
			startDate: nowTemp,

			pick: function (e) {
				// Formatted date for the booking engine
				$('input[name="datein"]').val( pad( e.date.getMonth() + 1*1 , 2) + '/' + e.date.getDate() + '/' + e.date.getFullYear() )
			}
		});

		var checkout = $checkOutField.datepicker({
			format: 'mm-dd-yy',
			autoPick: true,
			autoHide: true,
			date: null,
			startDate: tomorrow,
			pick: function (e) {
				// Formatted date for the booking engine
				$('input[name="dateout"]').val( pad( e.date.getMonth() + 1*1 , 2) + '/' + e.date.getDate() + '/' + e.date.getFullYear() );
			}
		});
	}


	$('.booking select').selectric();


	$('.booking__tabs-content-room-why-book').accordiom({
		showFirstItem: false
	});


	$('#booking__picker-select').on('change', function () {
		$('.tabs-panel').removeClass('is-active');
		$('#' + $(this).val()).addClass('is-active');
	});



	// Always open the booking widget for any booking links
	$('a[href*="reservations.travelclick.com"], a[href*="reservations.lyledc.com"], a[href*="reservations.onehundredshoreditch.com"], a[href*="be.synxis.com"]').not('.reservations__item-cta a').on('click', function () {

		//$('#booking').foundation('open');

		// Auto populate the room or rate codes if present in the URL
		if ( $(this).attr('href').indexOf('?') > -1 ) {
			var urlParams = new URLSearchParams( $(this).attr('href').split('?')[1] );
			if ( urlParams.has('RatePlanId') ) {
				$('input[name="RatePlanId"]').val( urlParams.get('RatePlanId') );
			}
			if ( urlParams.has('Groupid') ) {
				$('input[name="Groupid"]').val( urlParams.get('Groupid') );
			}
			if ( urlParams.has('RoomTypeID') ) {
				$('#book-rooms form').append('<input type="hidden" name="RoomTypeID" value="' + urlParams.get('RoomTypeID') + '">');
			}
			if ( urlParams.has('promo') ) {
				$('input[name="promo"]').val( urlParams.get('promo') );
			}
			if ( urlParams.has('rate') ) {
				$('input[name="rate"]').val( urlParams.get('rate') );
			}
			if ( urlParams.has('room') ) {
				$('input[name="room"]').val( urlParams.get('room') );
			}
		}

		$('#book-rooms form').trigger('submit');

		return false;

	});
}

function validateGHAPasswords($form) {
	var isValid = true;
	$form.find('.requirement').removeClass('fail');

	var $currentStep = $('.gha-sign-up__form-wrapper:visible, .gha-portal');
	if ($currentStep.find('input[type="password"]').length === 0) {
		return true;
	}

	var $password1 = $('.gha-sign-up__form-wrapper:visible #password, #NewPassword');
	var $password2 = $currentStep.find('#confirmpassword, #ConfirmPassword');
	
	if ($password1.val() !== $password2.val()) {
		$currentStep.find('.requirement.match').addClass('fail');
		isValid = false;
	}

	if ($password1.val().length < 8 || $password1.val().length > 50) {
		$currentStep.find('.requirement.length').addClass('fail');
		isValid = false;
	}

	if (!/[a-z]/.test($password1.val().toLowerCase())) {
		$currentStep.find('.requirement.letter').addClass('fail');
		isValid = false;
	}

	if (!/\d/.test($password1.val())) {
		$currentStep.find('.requirement.number').addClass('fail');
		isValid = false;
	}

	if (!/[#.?!@$%^&*-]/.test($password1.val())) {
		$currentStep.find('.requirement.symbol').addClass('fail');
		isValid = false;
	}
	
	if (/[^\x20-\x7E]+/.test($password1.val())) {
		$currentStep.find('.requirement.ascii').addClass('fail');
		isValid = false;
	}

	return isValid;
}
